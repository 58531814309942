import axios from 'axios'
import { Config } from '../../_helpers/Config'

const currentUserSubject = JSON.parse( localStorage.getItem('adminUser') )
if( currentUserSubject !== null ) axios.defaults.headers.common = {'Authorization': `Bearer ${currentUserSubject.token}`}

export const AuthenticationService = {
    login,
    logout,
    currentUser: currentUserSubject
}

async function login(username, password) {
    return await axios
        .post(`${Config.serveur}/admin/auth`, { username, password })
        .then( response => {
            if(response.status === 200) {
                if( response.data.success )  {
                    let user = response.data
                    localStorage.setItem('adminUser', JSON.stringify(user))
                    return user
                } else return Promise.reject("Identifiant ou mot de passe incorrect")
            }
        }).catch( error => {
            return Promise.reject(error)
        })
}

function logout() {
    localStorage.removeItem('adminUser')
}