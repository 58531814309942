import React, { useEffect, useState } from 'react'
import { Toast } from 'react-bootstrap'
import { Config } from '../_helpers/Config.js'
import axios from 'axios'

import {ProgressBar} from 'react-bootstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default function CampaignEdit(props) {

    const campaign_id = parseInt(props.match.params.campaignId)

    const [campaign, setCampaign] = useState(false)
    const [games, setGames] = useState(false)
    const [uploadPercentage, setUploadPercentage] = useState(0)

    const [notification, showNotification] = useState(false);
    const [successNotification, setSuccessNotification] = useState(false);
    const [bodyNotification, setBodyNotification] = useState("");

    useEffect(() => {
        fetchCampaign()
    }, // eslint-disable-next-line
    [])

    const fetchCampaign = () => {
        axios
        .get(`${Config.serveur}/admin/campaign/${campaign_id}`)
        .then(response => {
            if( response.status === 200 ) {
                // IF CAMPAIGN IS NOT NULL REASSIGN VALUES FOR THE FORM
                if( response.data.campaigns !== null) {
                    response.data.campaigns.days = response.data.campaigns.days !== null && response.data.campaigns.days.split(',')
                    response.data.campaigns.du = response.data.campaigns.du !== null && formatDate(response.data.campaigns.du)
                    response.data.campaigns.au = response.data.campaigns.au !== null && formatDate(response.data.campaigns.au)
                }
                // THE STATES
                setCampaign(response.data.campaigns)
                setGames(response.data.games)
            }
        }).catch( error => {
            alert('Désolé, une erreur est survenue, pour des raisons de sécurité vous avez été déconnecté.')
            document.location = '/signout'
        })
    }

    const uploadFile = ({ target: { files } }, field ) => {
        let data = new FormData()
        data.append( 'file', files[0] )

        const options = {
          onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor( (loaded * 100) / total )
            if( percent < 100 ) setUploadPercentage(percent)
          }
        }
    
        axios.post(`${Config.serveur}/admin/campaign/upload`, data, options).then( res => { 
            if( res.data.success === true ) { 
                notif(true, "Image envoyée avec succès !")
                campaign[field] = res.data.url 
            } else notif(false, res.data.message)

            setUploadPercentage(100)
            setTimeout(() => setUploadPercentage(0), 1000)
        })
      }

    const formatDate = (date) => {
        date = new Date(date)
        date = date.toLocaleDateString("fr-Fr")
        date = date.split("/")
        date = date[2] + "-" + date[1] + "-" + date[0]
        return date
    }

    const notif = (success, body) => {
        setSuccessNotification(success)
        setBodyNotification(body)
        showNotification(true)
    }

    const deletePicture = (field) => {
        campaign[field] = null
        if( window.confirm('Vouslez-vous réellement retirer cette image ?') ) 
        notif(true, "Photo retirée avec succès !")
    }

    return (
        <>
            <header className="sticky-top p-2 mb-4 bg-primary text-white">
                <div><h2>Modifier une campagne</h2></div>
                { uploadPercentage > 0 && <ProgressBar variant="warning" striped animated now={uploadPercentage} active label={`${uploadPercentage}%`} /> }
            </header>
            <div className="container-fluid">
            
                { campaign === null ? ( <div className="alert alert-danger">Cette campagne est inconnue.</div> ) : campaign === false ? ( <div className="alert alert-info">Chargement de la campagne...</div> ) : (
                
                <>

                <Toast style={{ position: 'fixed', top: 10, right: 10, zIndex: 99999 }} show={notification} delay={3000} autohide onClose={ () => showNotification(false) }>
                    <Toast.Header className={successNotification === true ? 'bg-success text-white' : 'bg-danger text-white'}><strong className="mr-auto">{successNotification === true ? 'Succès' : 'Erreur'}</strong></Toast.Header>
                    <Toast.Body>{bodyNotification}</Toast.Body>
                </Toast>

                <Formik

                    initialValues = { campaign }

                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Champs obligatoire')
                    })}

                    onSubmit={(fields, { setStatus, setSubmitting }) => {
                        axios
                        .post(`${Config.serveur}/admin/campaign/update`, fields)
                        .then( response => {
                            if(response.status === 200) {
                                if( response.data.success )  {
                                    notif(true, "Campagne enregistrée avec succès.")
                                    setSubmitting(false)
                                } else {
                                    setSubmitting(false)
                                    notif(false, "Impossible d'enregistrer la campagne")
                                }
                            }
                        }).catch( error => {
                            setSubmitting(false)
                            notif(false, "Impossible d'enregistrer la campagne")
                        })
                    }}

                    render={({ values, setFieldValue, errors, status, touched, isSubmitting, isValid }) => (
                        <Form>

                            <div className="p-4 mb-4 container rounded shadow">

                                <h2 className="bg-secondary mb-4 p-2 rounded text-white">La campagne</h2>

                                <div className="form-group">
                                    <label htmlFor="name">Nom de la campagne</label>
                                    <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="type">Type de jeu</label>
                                    <Field as="select" name="type" className="form-control">
                                        <option value="TAS">Tirage au sort</option>
                                        <option value="BEST">Meilleur score</option>
                                    </Field>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="score_table">Meilleur score ? Indiquer le nombre de joueurs du tableau de résultats</label>
                                    <Field name="score_table" type="number" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="title">Titre</label>
                                    <Field name="title" type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="claim">Claim</label>
                                    <Field as="textarea" name="claim" type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="frequence">Fréquence</label>
                                    <Field as="select" name="frequence" className="form-control">
                                        <option value="">Illimité</option>
                                        <option value="1/d">1 fois / jour</option>
                                        <option value="2/d">2 fois / jour</option>
                                        <option value="3/d">3 fois / jour</option>
                                        <option value="1/w">1 fois / semaine</option>
                                        <option value="1/m">1 fois / mois</option>
                                    </Field>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="banner">Bannière</label>
                                    <figure>{ campaign.banner != null && ( <img alt="Bannière" height="200" src={campaign.banner} /> )}</figure>
                                    <Field name="banner" type="hidden" className="form-control" />
                                    <Field name="banner_choose" type="file" accept="image/*" className="form-control" onChange={ (e) => uploadFile(e, "banner") } />
                                    { campaign.banner != null && ( <button type="button" className="btn btn-outline-danger" onClick={ () => deletePicture("banner") }>Retirer la photo</button> ) }
                                </div>

                            </div>

                            <div className="p-4 mb-4 container rounded shadow">

                                <h2 className="bg-secondary mb-4 p-2 rounded text-white">Le jeu</h2>

                                <div className="form-group">
                                    <label htmlFor="game_id">Jeu associé</label>
                                    <Field as="select" name="game_id" className="form-control">
                                        { games !== false && games.map( game => (
                                            <option key={game.id} value={game.id}>{game.name}</option>
                                        ) )}
                                    </Field>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="score_to_win">Score minium pour gagner (vide = tout le monde gagne)</label>
                                    <Field name="score_to_win" type="number" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="score_result">Résultat du score (Ex: Votre score est de %s points)</label>
                                    <Field name="score_result" type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="banner">Bannière de fin</label>
                                    <figure>{ campaign.score_banner != null && ( <img alt="Bannière" height="200" src={campaign.score_banner} /> )}</figure>
                                    <Field name="score_banner" type="hidden" className="form-control" />
                                    <Field name="score_banner_choose" type="file" accept="image/*" className="form-control" onChange={ (e) => uploadFile(e, "score_banner") } />
                                    { campaign.score_banner != null && ( <button type="button" className="btn btn-outline-danger" onClick={ () => deletePicture("score_banner") }>Retirer la photo</button> ) }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="score_title">Titre gagnant</label>
                                    <Field name="score_title" type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="score_claim">Claim gagnant</label>
                                    <Field name="score_claim" type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="banner">Bannière de fin perdant</label>
                                    <figure>{ campaign.score_banner_looser != null && ( <img alt="Bannière" height="200" src={campaign.score_banner_looser} /> )}</figure>
                                    <Field name="score_banner_looser" type="hidden" className="form-control" />
                                    <Field name="score_banner_looser_choose" type="file" accept="image/*" className="form-control" onChange={ (e) => uploadFile(e, "score_banner_looser") } />
                                    { campaign.score_banner_looser != null && ( <button type="button" className="btn btn-outline-danger" onClick={ () => deletePicture("score_banner_looser") }>Retirer la photo</button> ) }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="score_title_looser">Titre perdant</label>
                                    <Field name="score_title_looser" type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="score_claim_looser">Claim perdant</label>
                                    <Field name="score_claim_looser" type="text" className="form-control" />
                                </div>

                            </div>

                            <div className="p-4 mb-4 container rounded shadow">

                                <h2 className="bg-secondary mb-4 p-2 rounded text-white">La pèriode</h2>

                                <div className="form-group">
                                    <label htmlFor="du">Du</label>
                                    <Field name="du" type="date" className="form-control" />
                                </div>

                                <div className="form-group">
                                <label htmlFor="au">Au</label>
                                    <Field name="au" type="date" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="days">Jours</label>
                                    <Field as="select" name="days" multiple={true} className="form-control" >
                                        <option value="">Tous les jours</option>
                                        <option value="1">Lundi</option>
                                        <option value="2">Mardi</option>
                                        <option value="3">Mercredi</option>
                                        <option value="4">Jeudi</option>
                                        <option value="5">Vendredi</option>
                                        <option value="6">Samedi</option>
                                        <option value="7">Dimanche</option>
                                    </Field>
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="days_hours_from">Heure de début</label>
                                    <Field name="days_hours_from" type="time" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="days_hours_until">Heure de fin</label>
                                    <Field name="days_hours_until" type="time" className="form-control" />
                                </div>

                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" disabled={ ( isSubmitting || !isValid || uploadPercentage > 0 ) && true }>{!isSubmitting?"Enregistrer les modifications":"Enregistrement en cours..."}</button> 
                                { isSubmitting && <img alt="Enregistrement en cours..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />}
                            </div>

                        </Form>
                    )}
                />

                </>
                
            ) }
            </div>
        </>
    )
}