import React from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

import { history } from './_helpers/History';
import { Config } from './_helpers/Config';

// AUTH
import { AuthenticationService } from './components/auth/AuthenticationService'
import Signin from './components/auth/Signin'
import Signout from './components/auth/Signout'

// COMPONENTS
import Header from './components/Header'
import Dashboard from './components/Dashboard'
import Campaigns from './components/Campaigns'
import CampaignEdit from './components/CampaignEdit'
import Users from './components/Users'

function App() {

  const access = Config.key === window.location.href.replace(window.location.origin + "/", '')
  const user = AuthenticationService.currentUser

  return (
    <div className="">
        <Router history={history}>
          { user !== null ? (
            <>
              <div className="d-flex flex-row align-items-stretch">
                  <aside className="min-vh-100 sticky bg-light p-4">
                    <Header />
                    <ul className="list-group">
                      <li className="list-group-item"><Link to="/">Dashboard</Link></li>
                      <li className="list-group-item"><Link to="/campaigns">Les campagnes</Link></li>
                    </ul>
                  </aside>
                  <div className="flex-fill">
                      <Route exact path="/" component={Dashboard} /> 
                      <Route exact path="/campaigns" component={Campaigns} /> 
                      <Route exact path="/campaigns/:campaignId([0-9]+)/edit" component={CampaignEdit} /> 
                      <Route exact path="/campaigns/:campaignId([0-9]+)/users" component={Users} /> 
                      <Route path="/signout" component={Signout} /> 
                  </div>
              </div>
            </>
          ) : access && (
            <Signin />
          ) }
        </Router>
    </div>
  );
}

export default App;
