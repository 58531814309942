import React from 'react'
import { Link } from "react-router-dom"
import logo from "../assets/logo.png"

export default function Header() {
    return (
        <div className="d-flex mb-4 flex-column align-items-center">
            <div><img src={logo} alt="Logo ZEOP" /></div>
            <div><Link className="text-danger" to="/signout">Déconnexion</Link></div>
        </div>
    )
}