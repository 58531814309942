import React, { useEffect } from 'react'
//import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

export default function Dashboard(props) {

    useEffect(() => {
        
        

    }, [])

    return (
        <>
            <header className="sticky-top p-2 d-flex align-items-center bg-primary text-white">
                <div className="mr-auto"><h1>Dashboard</h1></div>
                <div>
                </div>
            </header>

            ...
            
        </>
    )

}