import React, { useEffect, useState } from 'react'
import { Config } from '../_helpers/Config.js'
import axios from 'axios'

export default function Users(props) {

    const campaign_id = parseInt(props.match.params.campaignId)

    const [users, setUsers] = useState(false)

    useEffect(() => {
        fetchUsers()
    }, // eslint-disable-next-line
    [])

    const fetchUsers = () => {
        axios
        .get(`${Config.serveur}/admin/campaign/${campaign_id}/users`)
        .then(response => {
            if( response.status === 200 ) {
                setUsers(response.data.users)
            }
        }).catch( error => {
            alert('Désolé, une erreur est survenue, pour des raisons de sécurité vous avez été déconnecté.')
            //document.location = '/signout'
        })
    }

    return (
        <>
            <header className="sticky-top p-2 mb-4 d-flex align-items-center bg-primary text-white">
                <div className="mr-auto"><h2>Les joueurs</h2></div>
                <div><a className="btn btn-secondary" rel="noopener noreferrer" target="_blank" href={`${Config.serveur}/admin/campaign/${campaign_id}/users/export`}>Exporter les joueurs</a></div>
            </header>
            <div className="container-fluid">
                { users === null ? ( <div className="alert alert-danger">Aucun joueur pour le moment.</div> ) : users === false ? ( <div className="alert alert-info">Chargement des joueurs...</div> ) : (
                    <>
                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th>ZEOP ID</th>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>E-mail</th>
                                <th>Tèl</th>
                                <th>Score</th>
                                <th>Winner</th>
                            </tr>
                        </thead>
                        <tbody>
                        { users.map( ( field, key ) => ( 
                        <tr key={key}>
                            <td>{field.zeop_id}</td>
                            <td>{field.firstname}</td>
                            <td>{field.lastname}</td>
                            <td>{field.email}</td>
                            <td>{field.tel}</td>
                            <td>{field.score}</td>
                            <td>{ field.winner === "1" ? "OUI" : "NON" }</td>
                        </tr> )) }
                        </tbody>
                    </table>
                    </>
                ) }
            </div>
        </>
    )

}