import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { Config } from '../_helpers/Config.js'
import axios from 'axios'

export default function Campaigns(props) {

    const [campaigns, setCampaigns] = useState(false)

    useEffect(() => {
        fetchCampaigns()
    }, // eslint-disable-next-line
    [])

    const fetchCampaigns = () => {
        axios
        .get(`${Config.serveur}/admin/campaigns`)
        .then(response => {
            if( response.status === 200 ) {
                setCampaigns(response.data.campaigns)
            }
        }).catch( error => {
            alert('Désolé, une erreur est survenue, pour des raisons de sécurité vous avez été déconnecté.')
            document.location = '/signout'
        })
    }

    return (
        <>
            <header className="sticky-top p-2 mb-4 d-flex align-items-center bg-primary text-white">
                <div className="mr-auto"><h2>Les campagnes</h2></div>
                <div></div>
            </header>
            <div className="container-fluid">
                { campaigns === null ? ( <div className="alert alert-danger">Aucune campagne n'est disponible.</div> ) : campaigns === false ? ( <div className="alert alert-info">Chargement des campagnes...</div> ) : (
                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Jeu</th>
                                <th>Pèriode</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        { campaigns.map( ( field, key ) => ( 
                        <tr key={key}>
                            <td>{field.name}</td>
                            <td>{field.game_name}</td>
                            <td>{field.periode}</td>
                            <td>
                                <Link className="btn btn-primary mr-3" to={`/campaigns/${field.id}/edit`}>Editer</Link> 
                                <Link className="btn btn-secondary" to={`/campaigns/${field.id}/users`}>Joueurs</Link>
                            </td>
                        </tr> )) }
                        </tbody>
                    </table>
                ) }
            </div>
        </>
    )

}